import request from '@/utils/request'

// 获取组织机构树形数据
export function WeGetOrganTree(data) {
    return request({
        url: 'Organ/WeGetOrganTree',
        method: 'post',
        headers: {'Content-Type':'multipart/form-data'},
        data
    })
}